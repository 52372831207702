<template>
  <main class="main">
    <div v-if="clickedImg" class="modal" @click.stop="clickedImg = null">
      <img :src="clickedImg" alt=""  >
    </div>
    <header>    
        <h1>vRealize Suite Lifecycle Manager</h1>
        <p class="text-mid">VMware</p>
      <div class="banner" alt="project thumbnail">
        <img src="@/assets/images/projects/vrslcm/banner.png" />
      </div>
    </header>
    <div class="sectin-3 project">
      <section class="section_page section-3">
        <p>vRealize Lifecycle Manager (vRSLCM) is a comprehensive application lifecycle and content management solution for vRealize Suite(vRS) - VMware's cloud management suite. It helps customers speed up deployments and updates, optimize and automate ongoing product and content management.</p>
        <p>vRSLCM has seen multiple revamps. The latest 8.x version integrated with vRS was first released in 2019.</p>
      </section>    
      <section class="section_page" id="lcm_role" >
        <h2>My Role</h2>
        <p>I joined VMware in 2020 as the <strong>first and sole designer</strong> for vRSLCM. As the lead designer, I am the <strong>key owner and driver of design across key aspects of the application</strong>.</p>
        <ul class="list-box">
          <div class="list-layout-col">
            <li class="box-li">
              <img src="@/assets/images/projects/vrslcm/icons/process.svg" role="presentation"/>
              <h3>Establishing Design Process</h3>
              <p>As the first designer in the team, I have been working with senior executives, product managers, and engineering managers on establishing a structured and transparent design process to handle all tactical and strategic projects.</p>       
            </li>
            <li class="box-li">
              <img src="@/assets/images/projects/vrslcm/icons/research.svg" role="presentation"/>
              <h3>Integrating UX Research</h3>
              <p>To establish a data-driven and customer-centric process, I am leading efforts to set up multiple avenues for data and participants.</p>
              <ul>
                <li>Leveraging Internal Product Feedback (IPF) and email surveys for direct customer feedback and establishing baseline UX metrics.</li>
                <li>Conducting workshops through <a target="_blank" rel="noopener noreferrer" href="https://www.vmware.com/design/research.html">VMware Design Studio sessions</a> for qualitative research.</li>                
                <li>Leading discussions for setting up regular <strong>Design Partner Program</strong> for vRSLCM with our customers.</li>
              </ul>            
            </li>
          </div>
          <div class="list-layout-col">
            <li class="box-li">
              <img src="@/assets/images/projects/vrslcm/icons/e2e.svg" role="presentation"/>
              <h3>End-to-End Stories (Design Strategy)</h3>
              <p>For strategic initiatives, I work with PM to create End-to-End stories for aligning new initiatives with VMware's central goals.</p>            
            </li>
            <li class="box-li">
              <img src="@/assets/images/projects/vrslcm/icons/standards.svg" role="presentation"/>
              <h3>UX Audits and compliance to standards</h3>
              <p>As with any legacy product, features and parts of vRSLCM are at different levels of UX maturity and with different usability issues. I carry out UX audits to categorize, prioritize UX improvements, and refactor components to match VMware Clarity(Design System) and accessibility standards.</p>             
              <p>Based on learnings from these, I have two written articles on <a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/thinking-in-frameworks-communicating-system-status-eaf6f7b58bab">communicating system status</a> and <a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/in-defense-of-reusing-existing-design-patterns-9d216e09dfce">reusing design patterns</a>.</p>
            </li>
            <li class="box-li">
              <img src="@/assets/images/projects/vrslcm/icons/collaboration.svg" role="presentation"/>
              <h3>Cross-Product Collaborations</h3>
              <p>I initiate and collaborate with other designers for the best and consistent cross-product customer experience across VMware products.</p>            
            </li>
          </div>
        </ul>        
      </section>
      <section class="section_page section-3" id="lcm_cs1">
        <h2>Case Study: Import Deployed Products</h2>
        <img src="@/assets/images/projects/vrslcm/autodiscover.png" alt="project output screen" @click="zoom"/>        
        <section class="section_sub">
          <h3 class="header-article-1">Background</h3>
          <p>VMware vRealize Suite is a cloud management solution which integrates 5 vRealize Cloud Management products to provide the most comprehensive management stack for private and public clouds.</p>
          <p>But the cloud management products need management too!</p>
          <p>To start managing products, <strong>customer can install a new version of a vRealize product or import an existing instance.</strong></p>
        </section>
        <section class="section_sub">
          <h3 class="header-article-1">Who are our users?</h3>
          <p>Meet Anita.</p>
          <p>Anita is a VI admin who is in charge of providing and maintaining virtual infrastructure, ensuring high levels of performance and avoiding downtime in Acme Corp. She (and her team) is using multiple vRealize suite products for her on-prem(ise) cloud management.</p>
          <p>To simplify the management of those products, she has installed vRSLCM.</p>
          <p><i>These are research-backed personas for the vRealize Suite.</i></p>
        </section>
        <section class="section_sub">
          <h3 class="header-article-1">Problem Space & Opportunity</h3>
          <section class="section_inner">
            <h4 class="header-article-2">🚩 What's the problem?</h4>
            <p>To start managing an existing product from vRSLCM, Anita has to import the product into an vRSLCM construct. To do this, she has to provide configuration details and credentials. Currently, Anita has to <strong>manually enter the configuration details</strong> after getting them from 2 other VMware products.</p>
            <figure>
              <img :src="require('@/assets/images/projects/vrslcm/conf.png')" @click="zoom"/>
              <figcaption>Some of current input fields</figcaption>
            </figure>     
          </section>
          <section class="section_inner">
            <h4 class="header-article-2">🆕 What changed?</h4>
            <p>The project was initiated when a technical feature came into fruition - <strong>we could now auto-fetch vRealize product instances deployed on a vCenter. </strong></p>
          </section>
          <section class="section_inner">
            <h4 class="header-article-2">✨ What did that mean?</h4>
            <p>vRSLCM can do the heavy lifting and fetch the list of Anita's deployed products. Anita needs to only provide required credentials to import them.</p>
            <p><strong>Thus we could automate part of Anita's work</strong>.</p>
          </section>
          <section class="section_inner">
            <h4 class="header-article-2">🔥 But what else could that mean?</h4>
            <p>This project was being run on a very tight timetable, and the initial solution was to just allow fetching products within the existing workflow.</p>
            <p>However, I wanted to take a step back to look into the import workflow independently, and understand Anita's journey and the pain-points.</p>            
            <p>Currently 'install' and 'import' use cases are merged into a single 'Create Environment' workflow - this allows the flexibility to install new components with existing ones. It handles all use cases. However, it also means that the <strong>simpler import flow is constrained by the complex requirements of new product installation.</strong></p>
            <figure>
              <img src="@/assets/images/projects/vrslcm/issue.png" alt="current complexity & extra steps in the flow to accomodate install workflow" @click="zoom" />
              <figcaption>Current complexity & extra steps in the flow to accomodate install workflow</figcaption>
            </figure>            
            <p>Thus the current import workflow is product-driven—resulting in a disconnected experience. <strong>Could we make it more context-driven and user-centered?</strong></p>            
          </section>
          <section class="section_inner">
            <h4 class="header-article-2">❌ The challenge</h4>
            <p>The common workflow has organically expanded to support more features and cases since it was initially developed; so refactoring it would require intensive research and years worth of technical efforts. So, the exploration turned to designing an alternate 'import' feature. This lead to two challenges:</p>            
            <ol>
              <li>Placing an intuitive and non-conflicting 'import' workflow apart from the existing one</li>
              <li>Working within the constraints of the rigid legacy system of allowing only one instance of a product in an environment</li>            
            </ol>
            <p class="callout">Our goal is to simply the user experience of import existing products within the limitations of current model.</p>
          </section>
        </section> 
        <section class="section_sub">
          <h3 class="header-article-1">Solution</h3>
          <section class="section_inner">
            <h4 class="header-article-2">🏃‍♀️ Design Approach</h4>
            <p>As mentioned earlier, I started by mapping out Anita's existing workflow in vRSLCM and the frictions in her journey.</p>
            <figure>
              <img src="@/assets/images/projects/vrslcm/flow_old.png" alt="graph of flow with painpoints: 1. Individual touchpoints across pages 2.Only 1 instance can be imported 3.Anita has to have product details ready 4.Anita has to navigate back & forth from request page" @click="zoom"/>
              <figcaption>Current flow & painpoints</figcaption>
            </figure>      
             
            <p>🗃️ Next, I dived into the <strong>VMware UX research depository</strong> and our <strong>product's internal product feedback (IPF) responses</strong> to find relevant data on our users' frustrations and expectations related to adopting vRSLCM and importing products into vRSLCM.</p>
            <div class="callout">
              <p>Based on these, we now had the problem statements:</p>
              <ul>
                <li>How might we simplify the import process, and remove unnecessary steps?</li>
                <li>How might we make the import use case more intuitive or visible?</li>
                <li>How might we integrate the new import functionality within the existing design?</li>                      
              </ul>
            </div>
            <p>Based on these, 🎯 success would mean:</p>
            <ul>
              <li>Increased Time To Value (TTV)</li>
              <li>Simplified navigation and discoverability</li>
              <li>Reduced steps</li>                      
            </ul>
            <p>💡 Ideation sessions were revolved around best bulk action experience, intuitive end-to-end product import journey and (last stages) drag-and-drop interaction model + accessibility.</p>
          </section>
          <section class="section_inner">
            <h3 class="header-article-2">📢 The Outcome</h3>
            <img src="@/assets/images/projects/vrslcm/outcome.png" alt="output screen with 2 columns to drag from fetched product list into environments" @click="zoom"/> 
            <h3 class="header-article-3">Impact</h3>
            <p>The new page made the task of importing products a <strong>seamless workflow</strong>, as opposed to the compilation of independent touchpoints earlier. Bulk imports <strong>reduced the number of steps and increased TTV</strong>.</p>                   
            <p class="header-article-3">Salient features:</p>
            <ul>            
              <li>
                <p>The new import workflow is provided as a contextual action in the vCenters list page. This is aligned with how user performs their task ('Add vCenter' → Fetch products (deployed on vCenters)). So, the feature is now easily discoverable as well more intuitive.</p>
                <figure>
                  <img src="@/assets/images/projects/vrslcm/contextual.png" alt="contextual button placement" @click="zoom"/>
                  <figcaption>Contextual starting point</figcaption>
                </figure>
                
              </li>            
              <li>
                <p>While the constraints of adding only one instance of a product in a vRSLCM environment stands, we are allowing to create multiple environments within the same flow to allow bulk imports.</p>
                <figure>
                  <img src="@/assets/images/projects/vrslcm/bulk.png" alt="allow adding another environment card in the same screen to allow bulk imports" @click="zoom"/>
                  <figcaption>Bulk imports</figcaption>
                </figure>
                
              </li>
              <li>Fetching deployed products and making users choose (as opposed to manually adding details), combined with bulk actions significantly cuts down the number of steps and TTV for a new customer migrating to vRSLCM.</li>
              <figure>
                  <img src="@/assets/images/projects/vrslcm/flow_new.png" alt="new user flow map"/>
                  <figcaption>New simplified user flow</figcaption>
              </figure>
              <li>Showing the deployed product lists (in the same screen) makes it possible for user to quickly verify if they have added the correct vCenter, reducing user errors.</li>
              <li>Providing the list of products not only simplifies the task, but it is only a easy quick win carved into the process to motivate users for the next steps.</li>
              <li>UI is updated with the current design standards but closely resembles the existing application in terms of functionality.</li>
              <figure>
                  <img src="@/assets/images/projects/vrslcm/annotated.png" alt="UI elements and design patterns" @click="zoom"/>
                  <figcaption>UI Patterns</figcaption>
              </figure>              
              <li>The UI introduces a new interaction model (drag & drop) to make tasks faster; however, an alternate option to perform the same action is provided for making it accessible.</li>           
            </ul>
          </section> 
        </section>      
              
        <section class="section_sub">
          <h3 class="header-article-1">Possible Improvements</h3>
          <ul>
            <li>Currently the reason for allowing only one instance of a particular product being allowed is shown through signpost and tooltips. However, as this flow is more targeted towards new users, the UI design can be used to make that information more upfront.</li>
            <li>New environment is made default card for new users, but may be an unnecessary card for existing users who want to fetch & add new instance to existing environment (edge case).</li>
            <li>The term 'Auto-discover' may not be self-explanatory for some of the users. We can revisit the UX copy to make it more clearer.</li>            
          </ul>
        </section>
      </section>
      <section class="section_page" id="lcm_projects">
        <h2>Some Notable Projects</h2>
        <p>These are brief overviews of some more major, impactful projects I have worked on. Feel free to ask me more about my process or designs.</p>
        <details class="section_page" id="lcm-projects">
          <summary class="summary-button">View Projects</summary>                 
          <ul class="list-section">
            <li>
              <h3 class="header-article-2">Topology Visualization</h3>
              <div class="grid-project">
                <div>
                  <p>The sheer amount of <strong>data our users have to deal with in our product is overwhelming to most decision makers</strong>.</p>
                  <p> <strong>The goal of this project was to organize, systematize, and visually represent the massive data to help users quickly and intuitively understand the state of the infrastructure at a glance</strong>.</p>
                  <p>The final outcome - after over 15 iterations - was the <strong>node-edge graph functioning as a tool for analysis and troubleshooting</strong>. Deliverables included a clarity and accessibility standard compliant component library and interactive Figma prototype.</p>
                </div>
                <div class="image-buffer">
                  <img class="project-thumbnail" src="@/assets/images/projects/vrslcm/topology.png" alt="Topology components and artifacts" @click="zoom" />
                </div>
              </div>
            </li>
            <li>
              <h3 class="header-article-2">Improved Product Upgrade Flow</h3>
              <div class="grid-project">
                <div>    
                  <p>vRSLCM was designed to simplify lifecycle management by reducing manual effort and time required by our users. A primary user flow -  product upgrade workflow -  is <strong>clunky and too long, leading to frustration and failures for  customers. There is a scope of improvement.</strong></p>
                  <p>This is a <strong>high-scope epic to redesign the end-to-end upgrade flow to minimise user effort, automate sub-tasks and accelerate time to value.</strong></p>
                  <p>The UX design process for this is <strong>centered around leveraging customer feedback and data</strong>. Apart from <strong>available customer feedback (through IPF and customer support team)</strong> I conducted a <strong>virtual user research workshop (Miro) session</strong> in a VMworld 2021 Design Studio with <strong>25 current and potential customers over 4 sessions</strong> to validate assumptions and gather required information.</p>
                  <p>Based on that, we (me & PM) are working on the <strong>current journey map, to-be journey-map and scoping requirements.</strong></p>            
                </div>
                <div class="image-buffer">
                  <figure class="project-thumbnail">
                  <img src="@/assets/images/projects/vrslcm/upgrade.png" alt="user research artefacts" @click="zoom"/>
                  <figcaption class="text-small text-inverse">This is an ongoing project. Images are format of the research activity & current journey. Data is removed as this project is still in its initial phase.</figcaption>
                  </figure>
                </div>
              </div>
            </li>
            <li>
              <h3 class="header-article-2">Simplified Information Architecture</h3>
              <div class="grid-project">
                <div>
                  <p>With a lot of complex functionalities added on over the years to vRSLCM, the <strong>information architecture and navigation has become extremely deep and complex.</strong></p>
                  <p>While redesigning the complete application would require intensive research and be a time-intensive effort for engineers, I decided to address the environment details page which houses some of the main functionalities of vRSLCM, and a layout which could be independently refactored without introducing inconsistency in the UX of vRSLCM.</p>
                  <p>Process included a heuristic evaluation, detailed content audit, and introducing new design/interaction patterns. The final designed <strong>reduced minimum clicks to 1 from 3 and maximum to 3 from 8.</strong></p>            
                  
                  <p>I have written a <a target="_blank" rel="noopener noreferrer" href="https://uxdesign.cc/designing-for-information-density-69775165a18e">Medium article</a> about choosing correct design patterns based on this project.</p>
                </div>
                <div class="image-buffer">
                  <img class="project-thumbnail" src="@/assets/images/projects/vrslcm/details.png" alt="UI screen for redesigned page" @click="zoom"/>
                </div>
              </div>
            </li>   
            <li>
              <h3 class="header-article-2">Outbound notification integration</h3>
              <div class="grid-project">
                <div>
                  <p>This was an MVP for outbound notification integrations in vRSLCM, and my work included included <strong>UX strategy for actionable insights, and policy design for delivery frequency</strong> to create a no spam, no noise high concise notifications. As a part of the process, I proposed and designed an optimized notification aggregation scheme.</p>
                  <p><strong>Deliverables included policy, UI screens (including SMTP settings) and notification content design.</strong></p>
                </div>
                <div class="image-buffer">
                  <img class="project-thumbnail" src="@/assets/images/projects/vrslcm/notifications.png" alt="UI screen for notification customization" @click="zoom"/>
                </div>
                </div>         
            </li>
            <li>
              <h3 class="header-article-2">Access Control</h3>
              <p>Designed the <strong>policies</strong> for a role-based access control and shared object model including users, groups, roles, & permissions, <strong>balancing customer needs and technical constraints.</strong></p>            
            </li>
            <li>
              <h3 class="header-article-2">Moving to SaaS</h3>
              <p>Working on various workflows to help customers adopt cloud and migrate from on-prem to SaaS.</p>            
            </li>
          </ul>
        </details>     
      </section> 
    </div>
  </main>  
</template>
<style scoped>
.scenario{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  grid-gap: calc(0.4 * var(--padding-sm));
  margin-top: var(--padding-sm)
}
.scene{
  background-color: #F3F5F9;
}
.scene-text{
  padding: var(--padding-md) var(--padding-sm)
}
p.scene-title + p{
  margin-top: 0;
}
.stepped-li p + p{
  margin: 0;
}

h2.heading-summary{  
  display: inline-flex;  
}
.grid-project{
  display: flex;
  flex-direction: column-reverse;
}
.image-buffer{
    width: 100%;
    height: 100%;
    background: linear-gradient(294.38deg, #6018DC 1.95%, #EEAD92 100%);
    display: grid;
    place-items: center;
}
 .project-thumbnail{
   padding: var(--padding-sm) 0;
    max-width: 90%;   
    object-fit: contain;    
  }
.quote{
  color: grey;
  font-size: 1.2rem;
  font-style: italic;
  padding: 1.2rem 0.8rem;
}
details>ul{
  padding: 0 0.4rem
}
.summary-button{
  color: var(--accent-color);  
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--header-color);  
  z-index: 100;
}
.modal>img{
  height: 90vh;
  object-fit: contain;
  margin: auto;
}
@media only screen and (min-width: 48rem) { 
  .list-box{
     grid-template-columns: repeat(2, 1fr);
  } 
  .grid-project{
    width: calc(100% + (4 * var(--padding-lg)));
    display: grid;
    grid-template-columns: 5fr 4fr;
    gap:var(--padding-md);
  }
 .project-thumbnail{
    height: auto;
    max-width: 90%; 
   
  }
 
}
</style>

<script>
//import { defineComponent } from '@vue/composition-api'

export default{  
  data(){
    return{
      clickedImg: null
    }
  },
    methods: {
      zoom(e){
        this.clickedImg=e.target.src;
        console.log(e.target.src)
      },
      anchorHashCheck() {
        if (window.location.hash === this.$route.hash) {
          const el = document.getElementById(this.$route.hash.slice(1))
          if (el) {
            window.scrollTo(0, el.offsetTop)
          }
        }
      },
  },
  mounted() {
    this.anchorHashCheck()
  },

}
</script>
